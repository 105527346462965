:root {
  --box-size-cqw: 80cqw;
  --box-size-num-moves: 5;
  --cell-size: calc(var(--box-size-cqw) / var(--box-size-num-moves));
  --body-background: #FFFFFF;
  --app-background: #1768AC;
}

body {
  background-color: var(--body-background);
  font-family: "Orbitron", sans-serif;
  color: var(--body-background);
  margin: 0;
  user-select: none;
}

.selectable {
  animation-name: grow;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  cursor: pointer;
}

.notSelectable {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
  cursor: default;
}

@keyframes grow {
  from {
    transform: scale(1, 1);
  }

  to {
    transform: scale(105%, 105%);
  }
}

.horizontalChoices {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@container app-content (min-width: 100px) {

  #selector {
    bottom: 50%;
    position: absolute;
    width: 100%;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  #outerContainer {
    width: var(--box-size-cqw);
    aspect-ratio: 1 /1;
    border-color: var(--primary-color);
    border-style: solid;
    border-width: 3px;
    margin: 10px auto;
    padding: calc((var(--cell-size) / 2));
    border-radius: 6px;
    max-height: 50cqh;
  }

  #optionsContainer {
    bottom: 50%;
    position: absolute;
    transform: translate(0, 50%);
    width: 100%;
  }

  .bottomBar {
    bottom: 5cqw;
    left: 5cqw;
    position: absolute;
    width: 90cqw;
    border-radius: 6px;
    height: 7cqh;
    font-size: var(--cell-size);
  }
}

[hidden] {
  display: none;
}

#starCounter {
  font-family: "Orbitron", sans-serif;
  margin-left: 5cqw;
}

.journeyBoard {
  font-weight: 400;
  font-style: normal;
  font-size: calc(var(--cell-size) * 0.7); /* Magic number: Test this on more screens */ 
}

.journeyBoardHeader {
  margin: 5cqh 0;
}

.hidden {
  visibility: hidden;
}

.levelBoard-number {
  margin: 3cqh 3cqw;
}

.levelAction {
  min-width: 20px;
  padding: 3px;
  border-radius: 6px;
}

.star {
  font-size: var(--cell-size);
  min-width: 20px;
  aspect-ratio: 1 /1;
  border-radius: 6px;
}

.active {
  visibility: visible;
}

.inactive {
  opacity: .1;
}

#selection {
  margin-top: 2cqh;
  -webkit-mask-image: -webkit-gradient(
    linear,
    top,
    bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(0deg, transparent 5%, #000 50%, transparent 95%);
  height: calc(2 * var(--cell-size));
  overflow: hidden;
  max-height: 20cqh;
}

#validateButtonContainer {
  max-height: 20cqh;
}

#selectorContainer {
  height: 100%;
  max-height: 800px;
  container-type: inline-size;
  container-name: app-content;
  position: fixed;
  margin: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  background-color: var(--app-background);
  text-align: center;
  border: 1px;
  border-radius: 6px;
}

#innerContainer {
  position: relative;
  height: 100%;
}

.activeBead {
  transform: translate(-50%, 50%);
  position: absolute;
}

.bead {
  width: var(--cell-size);
  height: var(--cell-size);
  transform-origin: center center;
}

.bead:hover {
  cursor: pointer;
}

#avatarBeadsContainer {
  display: flex;
  justify-content: stretch;
  flex-flow: wrap;
  align-items: stretch;
  max-height: 10cqh;
}

.avatarBead {
  font-size: calc(var(--cell-size) / 2);
}

img {
  width: var(--cell-size);
}

img.score {
  width: calc(var(--cell-size) * 0.7); /* Magic number: Test this on more screens */ 
}
